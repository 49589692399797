<template>
  <div class="orderList">
     <div class="title">
        <img src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>订单列表</span>
     </div>
     <div class="tab_list">
        <div :class="{'tab_nav': true, 'tab_gal': item.type == params.type}" v-for="(item, index) in orderTab" :key="index" @click="cutTab(item.type)">
            {{item.name}}
        </div>
     </div>
     <!-- 列表 -->
     <div class="order_list">
        <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
               v-if="orderList.length > 0"
            >
         <van-cell @click="godetail(item.orderId)" v-for="(item, index) in orderList" :key="index">
            <div class="order_nav" >
                <div class="status">
                    <div class="left">{{item.stateText}}</div>
                    <div class="right" v-if="item.state == 5">
                        <img src="../../../../src/assets/image/sales.png" alt="">退货售后
                    </div>
                </div>
                <div class="goods" v-for="(itemsub, indexsub) in item.sub" :key="indexsub">
                    <img :src="itemsub.topPic" alt="">
                    <div class="content">
                        <div class="text">{{itemsub.name}}</div>
                        <div class="price">￥{{itemsub.skuPrice}}</div>
                    </div>
                </div>
                <div class="hint" v-if="item.state == 5">
                    <span>退款中</span>
                    <tt>退款中</tt>
                </div>
                <div class="order_but">
                    <span v-if="item.state == 1" @click.stop="doBuy(item)">去支付</span>
                    <!-- <span v-if="item.state == 2 || item.state == 3" @click.stop="doRefund(item, index)">申请退款</span> -->
                    <span v-if="item.state == 3" @click.stop="gowuliu(item.orderId)">物流信息</span>
                </div>
            </div>
            </van-cell>
        </van-list>
        <van-empty v-if="orderList.length == 0" description="暂无订单" />
     </div>
        <bottomList></bottomList>
  </div>
</template>

<script>
import { getList, orderPay, orderRefund } from '@/api/order'
export default {
  name: "orderlist",

  data() {
    return {
        params: {
            type: 1,
            page: 1,
            rows: 20
        },
        orderList: [],
        orderTab: [
            {
                name: '待支付',
                type: 1
            },
            {
                name: '待发货',
                type: 2
            },
            {
                name: '待收货',
                type: 3
            },
            {
                name: '已完成',
                type: 4
            }
        ],
        payParams: {
            addressId: 0,
            note: "",
            list: [
            ],
            payWay: 0
        },
        loading: false,
        finished: true,
    };
  },

  mounted() {
    if (this.$route.params.type) {
        this.params.type = this.$route.params.type
    } else {
        this.params.type = 1
    }
    this.getOrderList()
  },

  methods: {
    // 加载更多
    onLoad() {
      if (!this.finished) {
        this.params.page += 1
        this.loading = true
        getList(this.params).then(res => {
        this.orderList = this.orderList.concat(res.data.list)
            // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (res.data.list.length !== this.params.rows) {
            this.finished = true
        }
        })
      }
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    godetail(id) {
        this.$router.push({
            name: `orderdetail`,
            params: {
                orderId: id
            }
        })
    },
    getOrderList() {
        getList(this.params).then(res => {
            this.orderList = res.data.list
            if (res.data.list.length == this.params.rows) {
                this.finished = false
            }
        })
    },
    // 切换tab
    cutTab(type) {
        this.params.type = type
        this.getOrderList()
    },
    // 去支付
    doBuy(obj) {
        // 开始支付
        var payData = {
            payWay: 0,
            orderId: obj.orderId
        }
        orderPay(payData).then(ress => {
            if (ress.state == 200) {
                window.open(ress.data.url)
            }
        })
    },
    // 申请退款
    doRefund(obj, index) {
        orderRefund({orderId: obj.orderId}).then(res => {
            if (res.state == 200) {
                this.$layer.quicktips('申请成功')
                this.orderList[index].state = 5
                this.orderList[index].stateText = '退款中'
            }
        })
    },
    // 跳转至物流信息的页面
    gowuliu(orderId) {
        this.$router.push({
            name: `logistics`,
            params: {
                orderId: orderId
            }
        })
    }
  },
};
</script>

<style lang="less" scoped>
.orderList{
    width: 100vw;
    height: 100vh;
   background: #F6F7FA;
}
.title{
    width: 100%;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 11;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
.tab_list{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50rem 24rem 0rem 24rem;
    .tab_nav{
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
        font-size: 14rem;
        color: #6F7177;
        padding: 14rem 0rem;
    }
    .tab_gal{
        position: relative;
        color: #333333;
        font-weight: 500;
        &::after {
            position: absolute;
            left: calc(50% - 9.5rem);
            bottom: 0rem;
            content: '';
            width: 19rem;
            height: 4rem;
            background: #DAB6A7;
        }
    }
}
.order_nav{
    background: #FFFFFF;
    padding: 0rem 12rem 12rem 12rem;
    width: calc(100vw - 24rem);
    margin-top: 10rem;
    .status{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12rem 0rem;
        border-bottom: 1rem solid #F6F7FA;
        .left{
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 13rem;
            color: #939297;
        }
        .right{
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 13rem;
            color: #000000;
            img{
                width: 16rem;
                vertical-align: middle;
                margin-right: 4rem;
            }
        }
    }
    .goods{
        display: flex;
        margin-top: 14rem;
        img{
            width: 66rem;
            height: 66rem;
        }
        .content{
            margin-left: 10rem;
            .text{
                height: 40rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 300;
                font-size: 14rem;
                color: #000000;
                line-height: 20rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .price{
                margin-top: 4rem;
                font-family: DINPro, DINPro;
                font-weight: 500;
                font-size: 14rem;
                color: #000000;
            }
        }
    }
    .hint{
        height: 30rem;
        line-height: 30rem;
        background: #F6F7FA;
        border-radius: 2rem;
        margin-top: 13rem;
        padding: 0rem 14rem;
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 13rem;
            color: #000000;
        }
        tt{
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 13rem;
            color: #939297;
            margin-left: 9rem;
        }
    }
    .order_but{
        margin-top: 12rem;
        display: flex;
        justify-content: right;
        span{
            display: inline-block;
            text-align: center;
            width: 72rem;
            height: 32rem;
            line-height: 32rem;
            border: 1rem solid #C5C8D3;
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 12rem;
            color: #000000;
            margin-left: 8rem;
        }
    }
}
.qrsh{
    background: #E2BFB0;
}
.van-cell{
    padding: 0rem;
}
</style>
